
import { defineComponent, PropType } from "vue";
import { Color } from "@/contract/color";
export default defineComponent({
  name: "ActionLine",
  props: {
    color: { type: String as PropType<Color> },
  },
  emits: ["click"],
  methods: {},
});
