import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { CardStatus, GameCard } from "../../../contract/game";

type GetterType = {
  [K in keyof Getters]: ReturnType<Getters[K]>;
};

export type Getters<S = State> = {
  correctAnswers(state: S): number;
  currentCardIndex(state: S): number;
  currentScore(state: S): number;
  currentCard(state: S, getters: GetterType): GameCard | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  currentCardIndex: state => {
    return state.cards.findIndex(
      card =>
        card.status === CardStatus.New ||
        card.status === CardStatus.SelectedWord
    );
  },
  currentCard: (state, getters) => {
    return state.cards[getters.currentCardIndex];
  },
  currentScore: state => {
    return state.cards
      .map((card): number => {
        if (card.status == CardStatus.Correct) {
          return 1;
        }
        return 0;
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  },
  correctAnswers: state => {
    return state.cards.filter(card => (card.status = CardStatus.Correct))
      .length;
  },
};
