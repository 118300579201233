import { MutationTree } from "vuex";
import { State } from "./index";

export enum MutationTypes {
  SET_WORDS = "SET_WORDS",
  START_LOADING = "START_LOADING",
  FINISHED_LOADING = "FINISHED_LOADING",
}

export type Mutations<S = State> = {
  [MutationTypes.SET_WORDS](state: S, words: Array<string>): void;
  [MutationTypes.START_LOADING](state: S): void;
  [MutationTypes.FINISHED_LOADING](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_WORDS](state, words) {
    state.words = words;
  },
  [MutationTypes.START_LOADING](state) {
    state.loading = true;
  },
  [MutationTypes.FINISHED_LOADING](state) {
    state.loading = false;
  },
};
