
import { defineComponent } from "vue";

export interface OptionListItem {
  text: string;
  link?: string;
  disabled?: boolean;
  onClick: () => void;
}

export default defineComponent({
  name: "option-list",
  props: {
    items: {
      type: Array as () => Array<OptionListItem>,
      required: true,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "coloredItems",
    },
  },
});
