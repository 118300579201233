
import { defineComponent, computed } from "vue";
import { mapActions } from "vuex";
import { ActionTypes } from "./store/modules/words";
import useStore from "./store";
export default defineComponent({
  setup: function() {
    const store = useStore();
    return {
      words: computed(() => store.state.words.words),
      cards: computed(() => store.state.words.cards),
      gameCards: computed(() => store.state.game.cards),
    };
  },
  data: function() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    ...mapActions({
      loadData: ActionTypes.loadWords, // map `this.add()` to `this.$store.dispatch('increment')`
    }),
  },
  created: function() {
    if (
      this.words === undefined ||
      this.words.length <= 0 ||
      this.cards === undefined ||
      this.cards.length <= 0
    ) {
      this.loadData();
    }
  },
});
