import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import {
  Menu,
  About,
  Splash,
  RoleSelection,
  Card,
  Hint,
  Result,
} from "../components/pages";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/select",
    name: "RoleSelection",
    component: RoleSelection,
  },
  { path: "/card", redirect: { name: "card", params: { index: 0 } } },
  {
    path: "/card/:index",
    name: "Card",
    component: Card,
  },
  {
    path: "/hint",
    name: "Hint",
    component: Hint,
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
  },
  {
    path: "/splash",
    name: "Splash",
    component: Splash,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
