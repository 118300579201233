
import { defineComponent } from "vue";
import OptionList from "@/components/atoms/OptionList.vue";
import Layout from "@/components/molecules/Layout.vue";

export default defineComponent({
  name: "RoleSelection",
  data: function() {
    return {
      options: [
        { text: "Tipp aufschreiben", link: "/hint" },
        { text: "Karte ziehen", link: "/card" },
      ],
    };
  },
  components: {
    OptionList,
    Layout,
  },
});
