<template>
  <Layout>
    <h1 class="title">Solo<br />Uno</h1>
  </Layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Layout } from "@/components/molecules/index.ts";

export default defineComponent({
  components: { Layout },
  mounted: function() {
    const that = this;
    setTimeout(() => {
      that.$router.push("/select");
    }, 4000);
  },
});
</script>

<style scoped>
.title {
  font-size: 3rem;
  color: var(--color-blue);
}
</style>
