
import { defineComponent, computed } from "vue";
import Layout from "@/components/molecules/Layout.vue";
import OptionList, { OptionListItem } from "@/components/atoms/OptionList.vue";
import ActionLine from "@/components/atoms/ActionLine.vue";
import ActionLines from "@/components/atoms/ActionLines.vue";
import useStore from "@/store";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/modules/game";
import { CardStatus } from "@/contract/game";
import { useRoute } from "vue-router";
import { Color } from "@/contract/color";

export default defineComponent({
  name: "Card",
  setup: function() {
    const store = useStore();
    const route = useRoute();

    return {
      nextPage: computed(() =>
        store.state.game.cards.length > Number(route.params.index) + 1
          ? { name: "Card", params: { index: Number(route.params.index) + 1 } }
          : { name: "Result" }
      ),
      cardIndex: computed(() => Number(route.params.index)),
      card: computed(() => store.state.game.cards[Number(route.params.index)]),
    };
  },
  data() {
    return {
      Color: Color,
      CardStatus: CardStatus,
    };
  },
  methods: {
    ...mapActions({
      selectWord: ActionTypes.selectWord,
      setAnswer: ActionTypes.setAnswer,
    }),
    setCorrectAnswer() {
      this.setAnswer({
        status: CardStatus.Correct,
        cardIndex: this.cardIndex,
      });
    },
    setWrongAnswer() {
      this.setAnswer({
        status: CardStatus.Wrong,
        cardIndex: this.cardIndex,
      });
    },
    setSkippedAnswer() {
      this.setAnswer({
        status: CardStatus.Skipped,
        cardIndex: this.cardIndex,
      });
    },
  },
  computed: {
    wordList: function(): Array<OptionListItem> {
      if (this.card === undefined) {
        return [];
      }

      return this.card.words.map((word, index) => {
        const item: OptionListItem = {
          text: word,
          onClick: () => this.selectWord({ word, cardIndex: this.cardIndex }),
          disabled:
            this.card?.selectedWord !== undefined &&
            index !== this.card?.selectedWord,
        };
        return item;
      });
    },
  },
  components: {
    OptionList,
    Layout,
    ActionLine,
    ActionLines,
  },
});
