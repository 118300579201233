<template>
  <div
    :class="{
      list: true,
      coloredItems: type === 'coloredItems',
      coloredSeperators: type === 'coloredSeperators',
    }"
  >
    <div
      v-for="item in items"
      :key="item.text"
      class="listItemWrapper"
      :class="{ disabled: item.disabled }"
    >
      <router-link
        v-if="item.link"
        :to="item.link"
        class="listItem"
        @click="
          () => {
            item.onClick();
          }
        "
      >
        {{ item.text }}
      </router-link>
      <div
        v-if="!item.link"
        class="listItem"
        @click="
          () => {
            item.onClick();
          }
        "
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export interface OptionListItem {
  text: string;
  link?: string;
  disabled?: boolean;
  onClick: () => void;
}

export default defineComponent({
  name: "option-list",
  props: {
    items: {
      type: Array as () => Array<OptionListItem>,
      required: true,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "coloredItems",
    },
  },
});
</script>

<style scoped>
.list {
  display: block;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  --listitem-seperator-width: 6px;
  padding: 3rem 1rem;
  width: 80%;
}

.listItem {
  text-decoration: none;
  color: rgb(95, 95, 95);
  letter-spacing: 4px;
}

.listItemWrapper.disabled .listItem {
  color: var(--color-lightgrey);
}

.listItemWrapper:not(:last-child):after {
  content: " ";
  display: block;
  border-top-width: var(--listitem-seperator-width);
  border-top-style: solid;
  border-top-width: var(--listitem-seperator-width);
  border-top-style: solid;
  border-radius: var(--listitem-seperator-width);
  border-color: var(--color-grey);
  margin: 1rem 0;
}

.coloredSeperators .listItemWrapper:nth-of-type(7n + 1):after {
  border-color: var(--color-red);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n + 2):after {
  border-color: var(--color-purple);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n + 3):after {
  border-color: var(--color-violet);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n + 4):after {
  border-color: var(--color-blue);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n + 5):after {
  border-color: var(--color-green);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n + 6):after {
  border-color: var(--color-yellow);
}
.coloredSeperators .listItemWrapper:nth-of-type(7n):after {
  border-color: var(--color-orange);
}

.coloredItems .listItemWrapper:nth-of-type(7n + 1) .listItem {
  color: var(--color-red);
}
.coloredItems .listItemWrapper:nth-of-type(7n + 2) .listItem {
  color: var(--color-purple);
}
.coloredItems.listItemWrapper:nth-of-type(7n + 3) .listItem {
  color: var(--color-violet);
}
.coloredItems .listItemWrapper:nth-of-type(7n + 4) .listItem {
  color: var(--color-blue);
}
.coloredItems .listItemWrapper:nth-of-type(7n + 5) .listItem {
  color: var(--color-green);
}
.coloredItems .listItemWrapper:nth-of-type(7n + 6) .listItem {
  color: var(--color-yellow);
}
.coloredItems .listItemWrapper:nth-of-type(7n) .listItem {
  color: var(--color-orange);
}
</style>
