
import { defineComponent } from "vue";
import OptionList from "@/components/atoms/OptionList.vue";
import Layout from "@/components/molecules/Layout.vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/modules/game";

export default defineComponent({
  name: "Menu",
  data: function() {
    const self = this;
    return {
      options: [
        {
          text: "Neues Spiel",
          link: "/card/0",
          method: self.resetGame,
        },
        {
          text: "Credits",
          link: "/credits",
          method: self.resetGame,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      resetGame: ActionTypes.resetGame,
    }),
  },
  components: {
    OptionList,
    Layout,
  },
});
