export interface Card {
  words: Array<string>;
}

export interface GameCard extends Card {
  selectedWord?: number;
  answer?: string;
  status: CardStatus;
}

export enum CardType {
  Random = "random",
  Standard = "standard",
}
export enum CardStatus {
  New = "new",
  SelectedWord = "selectedWord",
  Correct = "correct",
  Skipped = "skipped",
  Wrong = "wrong",
  Discarded = "discarded",
}
