import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { key, store } from "./store";
import { devtools } from "@/devtools";
import "./main.css";

// createApp(App)

createApp(App)
  .use(devtools)
  .use(router)
  .use(store, key)
  .mount("#app");
