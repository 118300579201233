<template>
  <Layout>
    <OptionList :items="wordList" type="coloredSeperators"></OptionList>
    <ActionLines v-if="card?.status !== CardStatus.New">
      <ActionLine
        :color="Color.Green"
        v-if="
          card?.status === CardStatus.Correct ||
            card?.status === CardStatus.SelectedWord
        "
      >
        <router-link class="correct" @click="setCorrectAnswer" :to="nextPage">
          <img alt="checkmark" class="icon" src="@/assets/checkmark.svg" />
        </router-link>
      </ActionLine>
      <ActionLine
        :color="Color.Grey"
        v-if="card?.status === CardStatus.Discarded"
      >
        <router-link class="pass" :to="nextPage">
          <img alt="discard" class="icon" src="@/assets/discard.svg" />
        </router-link>
      </ActionLine>
      <ActionLine
        :color="Color.Grey"
        v-if="
          card?.status === CardStatus.Skipped ||
            card?.status === CardStatus.SelectedWord
        "
      >
        <router-link class="pass" @click="setSkippedAnswer" :to="nextPage">
          <img alt="minus" class="icon" src="@/assets/minus.svg" />
        </router-link>
      </ActionLine>
      <ActionLine
        :color="Color.Red"
        v-if="
          card?.status === CardStatus.Wrong ||
            card?.status === CardStatus.SelectedWord
        "
      >
        <router-link class="wrong" @click="setWrongAnswer" :to="nextPage">
          <img alt="cross" class="icon" src="@/assets/cross.svg" />
        </router-link>
      </ActionLine>
    </ActionLines>
  </Layout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import Layout from "@/components/molecules/Layout.vue";
import OptionList, { OptionListItem } from "@/components/atoms/OptionList.vue";
import ActionLine from "@/components/atoms/ActionLine.vue";
import ActionLines from "@/components/atoms/ActionLines.vue";
import useStore from "@/store";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/modules/game";
import { CardStatus } from "@/contract/game";
import { useRoute } from "vue-router";
import { Color } from "@/contract/color";

export default defineComponent({
  name: "Card",
  setup: function() {
    const store = useStore();
    const route = useRoute();

    return {
      nextPage: computed(() =>
        store.state.game.cards.length > Number(route.params.index) + 1
          ? { name: "Card", params: { index: Number(route.params.index) + 1 } }
          : { name: "Result" }
      ),
      cardIndex: computed(() => Number(route.params.index)),
      card: computed(() => store.state.game.cards[Number(route.params.index)]),
    };
  },
  data() {
    return {
      Color: Color,
      CardStatus: CardStatus,
    };
  },
  methods: {
    ...mapActions({
      selectWord: ActionTypes.selectWord,
      setAnswer: ActionTypes.setAnswer,
    }),
    setCorrectAnswer() {
      this.setAnswer({
        status: CardStatus.Correct,
        cardIndex: this.cardIndex,
      });
    },
    setWrongAnswer() {
      this.setAnswer({
        status: CardStatus.Wrong,
        cardIndex: this.cardIndex,
      });
    },
    setSkippedAnswer() {
      this.setAnswer({
        status: CardStatus.Skipped,
        cardIndex: this.cardIndex,
      });
    },
  },
  computed: {
    wordList: function(): Array<OptionListItem> {
      if (this.card === undefined) {
        return [];
      }

      return this.card.words.map((word, index) => {
        const item: OptionListItem = {
          text: word,
          onClick: () => this.selectWord({ word, cardIndex: this.cardIndex }),
          disabled:
            this.card?.selectedWord !== undefined &&
            index !== this.card?.selectedWord,
        };
        return item;
      });
    },
  },
  components: {
    OptionList,
    Layout,
    ActionLine,
    ActionLines,
  },
});
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.result {
  font-size: 3rem;
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
}

.correct,
.wrong,
.pass {
  text-decoration: none;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
}
</style>
