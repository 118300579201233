
import { defineComponent } from "vue";
import { Layout } from "@/components/molecules/index.ts";

export default defineComponent({
  components: { Layout },
  mounted: function() {
    const that = this;
    setTimeout(() => {
      that.$router.push("/select");
    }, 4000);
  },
});
