import { CardStatus, GameCard } from "@/contract/game";
import { MutationTree } from "vuex";
import { State } from "./index";

export enum MutationTypes {
  RESET_GAME = "RESET_GAME",
  SELECT_WORD = "SELECT_WORD",
  SET_ANSWER = "SET_ANSWER",
  SET_STATUS = "SET_STATUS",
}

export type Mutations<S = State> = {
  [MutationTypes.RESET_GAME](state: S, cards: Array<GameCard>): void;
  [MutationTypes.SELECT_WORD](
    state: S,
    payload: { word: string; cardIndex: number }
  ): void;
  [MutationTypes.SET_ANSWER](
    state: S,
    payload: { answer: string; cardIndex: number }
  ): void;
  [MutationTypes.SET_STATUS](
    state: S,
    payload: { status: CardStatus; cardIndex: number }
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET_GAME](state, cards) {
    state.cards = cards;
  },
  [MutationTypes.SELECT_WORD](state, payload) {
    const { word, cardIndex } = payload;
    state.cards[cardIndex] = {
      ...state.cards[cardIndex],
      status: CardStatus.SelectedWord,
      selectedWord: state.cards[cardIndex].words.findIndex(
        cardWord => cardWord === word
      ),
    };
  },
  [MutationTypes.SET_ANSWER](state, payload) {
    const { answer, cardIndex } = payload;
    state.cards[cardIndex] = {
      ...state.cards[cardIndex],
      answer: answer,
    };
  },
  [MutationTypes.SET_STATUS](state, payload) {
    const { status, cardIndex } = payload;
    state.cards[cardIndex] = {
      ...state.cards[cardIndex],
      status: status,
    };
    if (status === CardStatus.Wrong && state.cards.length > cardIndex + 2) {
      state.cards[cardIndex + 1] = {
        ...state.cards[cardIndex + 1],
        status: CardStatus.Discarded,
      };
    }
  },
};
