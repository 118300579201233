<template>
  <div class="actionLines">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data: () => {
    return {
      colors: ["yellow", "orange", "red", "purple", "violet", "blue", "green"],
    };
  },
  methods: {
    getLineLength: function(index: number, length?: number) {
      length = length === undefined ? this.colors.length : length;
      return (
        Math.sin((index / (length + 2) + 0.3) * -3 * Math.PI) * 0.25 + 0.75
      );
    },
  },
});
</script>

<style scoped>
.actionLines {
  display: flex;
  width: 100%;
  position: relative;
  align-items: flex-end;
  justify-content: space-around;
  height: 100px;
}
.colorLine {
  width: auto;
  flex: 1 1 100px;
  border-radius: 0 0 1000px 1000px;
}
</style>
