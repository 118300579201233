export enum Color {
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
  Purple = "purple",
  Violet = "violet",
  Blue = "blue",
  Green = "green",
  Grey = "grey",
}
