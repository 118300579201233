import { InjectionKey } from "vue";
import {
  createStore,
  Store as VuexStore,
  useStore as baseUseStore,
} from "vuex";
import game, { State as GameState, Store as GameStore } from "./modules/game";
import words, {
  State as WordsState,
  Store as WordsStore,
} from "./modules/words";

export interface RootState {
  game: GameState;
  words: WordsState;
  /* other: OtherState; */
}

export type RootStore = GameStore<Pick<RootState, "game">> &
  WordsStore<Pick<RootState, "words">>;

// define injection key
export const key: InjectionKey<VuexStore<RootState>> = Symbol();
export const store = createStore<RootState>({
  modules: {
    game,
    words,
  },
});

export default function useStore(): RootStore {
  return baseUseStore(key);
}
