import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import router from "@/router";
import { CardStatus } from "@/contract/game";

export enum ActionTypes {
  resetGame = "resetGame",
  selectWord = "selectWord",
  setAnswer = "setAnswer",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.resetGame]({ commit }: AugmentedActionContext): void;
  [ActionTypes.selectWord](
    { commit }: AugmentedActionContext,
    payload: {
      word: string;
      cardIndex?: number;
    }
  ): void;
  [ActionTypes.setAnswer](
    { commit }: AugmentedActionContext,
    payload: {
      status: CardStatus;
      answer?: string;
      cardIndex?: number;
    }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.resetGame]({ commit, rootGetters }) {
    console.log("generating a new game...");
    commit(
      MutationTypes.RESET_GAME,
      Array.from({ length: 13 }, () => {
        return rootGetters.randomCard;
      })
    );
  },
  [ActionTypes.selectWord]({ commit, getters }, payload) {
    const mutationPayload = {
      ...payload,
      cardIndex:
        payload.cardIndex === undefined
          ? getters.currentCardIndex
          : payload.cardIndex,
    };
    commit(MutationTypes.SELECT_WORD, mutationPayload);
  },
  [ActionTypes.setAnswer]({ commit, getters }, { cardIndex, status, answer }) {
    const correctCardIndex: number =
      cardIndex === undefined ? getters.currentCardIndex : cardIndex;

    if (answer !== undefined) {
      const answerPayload = {
        cardIndex: correctCardIndex,
        answer,
      };
      commit(MutationTypes.SET_ANSWER, answerPayload);
    }

    const statusPayload = {
      cardIndex: correctCardIndex,
      status,
    };
    commit(MutationTypes.SET_STATUS, statusPayload);
  },
};
