
import { defineComponent } from "vue";
import { Color } from "@/contract/color";

export default defineComponent({
  name: "RoleSelection",
  props: {
    color: {
      type: String,
      default: Color.Yellow,
    },
  },
  data: function() {
    return {
      hint: "",
    };
  },
});
