
import { defineComponent } from "vue";
import { ColorLines } from "@/components/atoms/index.ts";

export default defineComponent({
  name: "Layout",
  components: {
    ColorLines,
  },
});
