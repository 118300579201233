
import { defineComponent } from "vue";
export default defineComponent({
  data: () => {
    return {
      colors: ["yellow", "orange", "red", "purple", "violet", "blue", "green"],
    };
  },
  methods: {
    getLineLength: function(index: number, length?: number) {
      length = length === undefined ? this.colors.length : length;
      return (
        Math.sin((index / (length + 2) + 0.3) * -3 * Math.PI) * 0.25 + 0.75
      );
    },
  },
});
