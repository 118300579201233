<template>
  <Layout>
    <div class="resultText">{{ rating[currentScore] }}</div>
    <div class="resultNumbers">{{ `${currentScore} / 13` }}</div>
    <OptionList :items="[{ text: 'Zurück zum Menü', link: '/' }]"></OptionList>
  </Layout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import OptionList from "@/components/atoms/OptionList.vue";
import Layout from "@/components/molecules/Layout.vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/modules/game";
import useStore from "@/store";

export default defineComponent({
  name: "Result",
  setup: function() {
    const store = useStore();
    return {
      correctAnswers: computed(() => store.getters.correctAnswers),
      currentScore: computed(() => store.getters.currentScore),
    };
  },
  data: function() {
    return {
      rating: [
        "Übung macht den Meister.",
        "Übung macht den Meister.",
        "Übung macht den Meister.",
        "Übung macht den Meister.",
        "Das ist ein guter Anfang. Versucht es nochmal!",
        "Das ist ein guter Anfang. Versucht es nochmal!",
        "Das ist ein guter Anfang. Versucht es nochmal!",
        "Ein beachtliches Ergebnis. Könnt ihr das noch besser?",
        "Ein beachtliches Ergebnis. Könnt ihr das noch besser?",
        "Wow, ihr seid ein starkes Team!",
        "Wow, ihr seid ein starkes Team!",
        "Genial! Dieses Ergebnis kann sich sehen lassen!",
        "Unglaublich! Ihr seid Just One vom perfekten Ergebnis entfernt.",
        "Perfektes Ergebnis! Schafft ihr das nochmal?",
      ],
    };
  },
  methods: {
    ...mapActions({
      resetGame: ActionTypes.resetGame,
    }),
  },
  components: {
    OptionList,
    Layout,
  },
});
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.resultText {
  margin-top: 3rem;
  padding: 2rem 2rem 0.5rem 2rem;
  font-size: 2rem;
}
</style>
