<template>
  <article class="layout">
    <header><ColorLines /></header>
    <main class="main">
      <slot></slot>
    </main>
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ColorLines } from "@/components/atoms/index.ts";

export default defineComponent({
  name: "Layout",
  components: {
    ColorLines,
  },
});
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
header {
  flex: 1 1 5rem;
}
.main {
  /* flex: 1 1 10rem; */
  overflow: auto;
}
</style>
