<template>
  <layout>
    <OptionList :items="options"></OptionList>
  </layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OptionList from "@/components/atoms/OptionList.vue";
import Layout from "@/components/molecules/Layout.vue";

export default defineComponent({
  name: "RoleSelection",
  data: function() {
    return {
      options: [
        { text: "Tipp aufschreiben", link: "/hint" },
        { text: "Karte ziehen", link: "/card" },
      ],
    };
  },
  components: {
    OptionList,
    Layout,
  },
});
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
</style>
