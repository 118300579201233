<template>
  <article
    class="hint_wrapper"
    :style="{ backgroundColor: `var(--color-${color})` }"
  >
    <router-link class="close" to="/select">✖</router-link>
    <!-- <ResizeAuto> -->
    <textarea rows="2" class="hint__input" placeholder="Hinweis"></textarea>
    <!-- </ResizeAuto> -->
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Color } from "@/contract/color";

export default defineComponent({
  name: "RoleSelection",
  props: {
    color: {
      type: String,
      default: Color.Yellow,
    },
  },
  data: function() {
    return {
      hint: "",
    };
  },
});
</script>

<style scoped>
.close {
  text-decoration: none;
  color: #333;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  display: block;
}

.hint_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  font-size: 10vw;
  padding: 10vw;
  justify-content: center;
}
.hint__input:active,
.hint__input:focus,
.hint__input {
  font-size: 10vw;

  background-color: transparent;
  border: 0 none transparent;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}
</style>
