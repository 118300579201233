import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";
import { ActionTypes as GameActionTypes } from "@/store/modules/game";

export enum ActionTypes {
  loadWords = "loadWords",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.loadWords](
    { commit }: AugmentedActionContext,
    payload: any
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.loadWords]({ commit, dispatch }, payload) {
    console.log("Start loading words...");
    fetch("/words.json")
      .then(response => response.json())
      .then((data: Array<string>) => {
        console.log("Finished loading words");
        commit(MutationTypes.SET_WORDS, data);
        dispatch(GameActionTypes.resetGame);
      })
      .catch(error => console.error("Fetching words failed", error));
  },
};
