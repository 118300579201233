import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { Card, CardStatus, CardType, GameCard } from "../../../contract/game";

type GetterType = {
  [K in keyof Getters]: ReturnType<Getters[K]>;
};

export type Getters<S = State> = {
  randomCard(state: S, type: CardType): Card;
};

export const getters: GetterTree<State, RootState> & Getters = {
  randomCard: (state, type = CardType.Random): GameCard => {
    return {
      status: CardStatus.New,
      words: getRandomWordsForCard(state.words),
      selectedWord: undefined,
      answer: undefined,
    };
  },
};

function getRandomWordsForCard(words: Array<string>) {
  let cardWords: Array<string> = [];
  for (let index = 0; index < 5; index++) {
    let newWord: string;
    do {
      newWord = getRandomWord(words);
    } while (cardWords.includes(newWord));
    cardWords.push(newWord);
  }
  return cardWords;
}

function getRandomWord(words: Array<string>): string {
  const randomIndex = Math.floor(Math.random() * words.length);
  return words[randomIndex];
}
