<template>
  <button
    @click="$emit('click')"
    :style="{
      backgroundColor: `var(--color-${color})`,
    }"
    class="actionLine"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Color } from "@/contract/color";
export default defineComponent({
  name: "ActionLine",
  props: {
    color: { type: String as PropType<Color> },
  },
  emits: ["click"],
  methods: {},
});
</script>

<style scoped>
.actionLine {
  width: auto;
  /* flex: 1 1 100px; */
  border-radius: 1000px 1000px 0 0;
  border: 0;
  color: white;
  padding-top: 1rem;
  height: 100%;
  min-width: 14.2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
</style>
